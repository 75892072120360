<template>
  <div class="c-load-more">
    <TheButton v-if="!loading" class="c-load-more__btn" icon="IconLoadMore" @click="loadMore">{{ $t('LOAD_MORE') }}</TheButton>
    <LoadingDots v-else />
  </div>
</template>

<script>
import LoadingDots from '@/components/LoadingDots'
import TheButton from '@/components/TheButton'

export default {
  name: 'LoadMore',

  components: {
    LoadingDots,
    TheButton
  },

  props: {
    loading: Boolean
  },

  setup(props, { emit }) {
    const loadMore = () => {
      emit('load-more')
    }

    return {
      loadMore
    }
  }
}
</script>

<style lang="scss">
.c-load-more {
  margin-top: 3rem;
}

.c-load-more__btn {
  font-weight: 500;
}
</style>