import { fetchSH } from '@/api'
import { buildQuery } from '@/utils'

const endpoint = 'assets'

/* Content Objects CRUD */
const getAssetsAll = (namespace, query) =>
  fetchSH(`${endpoint}/${namespace}/${buildQuery(query)}`)
const getAsset = (namespace, id) => fetchSH(`${endpoint}/${namespace}/${id}`)

export { getAssetsAll, getAsset }
