<template>
  <div class="c-tools-item">
    <div class="c-tools-item__wrapper">
      <div v-if="icon" class="c-tools-item__icon">
        <Icon :icon="icon" />
      </div>
      <div class="c-tools-item__name">
        {{ file.name }}
      </div>
      <div class="c-tools-item__buttons">
        <TheButton class="c-tools-item__btn" v-if="false">{{
          $t('TOOLS_SEE_MORE')
        }}</TheButton>
        <TheButton class="c-tools-item__btn" @click="downloadFile(file)">{{
          $t('TOOLS_DOWNLOAD')
        }}</TheButton>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { saveAs } from 'file-saver'
import { getAsset } from '@/services/assets'
import { getObject } from '@/services/contents'
import Icon from '@/components/Icon'
import TheButton from '@/components/TheButton'

export default {
  name: 'ToolsItem',

  props: {
    file: Object,
  },

  components: {
    Icon,
    TheButton,
  },

  setup(props) {
    const icon = computed(() => {
      const icons = {
        doc: 'IconDoc',
        pdf: 'IconPDF',
        ppt: 'IconPPT',
        xls: 'IconXLS',
      }
      const filename = props.file.name
      const ext = filename.split('.').pop()
      let iconName = ''

      Object.keys(icons).forEach((i) => {
        if (ext.indexOf(i) !== -1) {
          iconName = i
        }
      })

      return icons[iconName]
    })

    const downloadFile = async (file) => {
      const object = await getObject(file.namespace, file.contentId)
      let assetId = object.data.content.data.content.assetId

      if (object.data.content.data.localization) {
        assetId = object.data.content.data.localization.assetId
      }
      const asset = await getAsset(file.namespace, assetId)
      const fileExternal = await fetch(asset.url)
      const blob = await fileExternal.blob()

      saveAs(blob, file.name)
    }

    return {
      icon,
      downloadFile,
    }
  },
}
</script>

<style lang="scss">
.c-tools-item {
  margin: 1.25rem 0;
  max-width: 36.875rem;
  &__icon {
    display: inline-block;
    margin-right: 0.75rem;
  }
  &__wrapper {
    display: flex;
    align-items: center;

    @include bp-down(530px) {
      padding: 1rem;
      background: $color-blue-light;
      flex-wrap: wrap;
    }
  }
  &__name {
    font-size: 1rem;
    color: $color-black;
    font-weight: 500;
  }

  &__buttons {
    margin-left: auto;

    @include bp-down(530px) {
      margin-top: 0.5rem;
      flex-basis: 100%;
    }
  }
  &__btn {
    font-weight: 500;
    white-space: nowrap;
    text-decoration: underline;
  }
}
</style>
