<template>
  <div class="v-tools">
    <TheHeading level="h1">{{ chapter.name }}</TheHeading>
    <div class="v-tools__wrapper">
      <div class="v-tools__description" v-md-html="chapter.description" />
      <ToolsFilters v-if="false" class="v-tools__filters" :filters="filters" />

      <div class="v-tools__items-wrapper">
        <div class="v-tools__items" v-for="file in files" :key="file.id">
          <ToolsItem :file="file" />
        </div>
        <LoadMore v-if="canLoadMore" :loading="loading" @load-more="loadMore" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import TheHeading from '@/components/TheHeading'
import ToolsFilters from '@/components/ToolsFilters'
import ToolsItem from '@/components/ToolsItem'
import LoadMore from '@/components/LoadMore'

export default {
  name: 'Tools',

  components: {
    TheHeading,
    ToolsFilters,
    ToolsItem,
    LoadMore,
  },

  setup() {
    const store = useStore()
    const { locale } = useI18n()
    const chapter = computed(() =>
      store.getters['content/getStructuresBySlugPath']('verktoy'),
    )
    const filters = ref([
      {
        id: '',
        name: 'Alle',
      },
      {
        id: 'neringslivsokonomi',
        name: 'Næringslivsøkonomi',
      },
      {
        id: 'entreprenorskap',
        name: 'Entreprenørskap og bedriftsutvikling',
      },
      {
        id: 'markedsforing',
        name: 'Markedsføring og ledelse',
      },
    ])
    const files = computed(() =>
      store.getters['content/getStructuresChildrens'](
        chapter.value.id,
        'OBJECT',
      ),
    )
    const pagination = ref(null)
    const loading = ref(false)

    const canLoadMore = computed(() => {
      return pagination.value && pagination.value.next
    })
    const loadMore = async () => {
      if (loading.value || (pagination.value && !pagination.value.next)) return
      loading.value = true
      const objectsPart = await store.dispatch(
        'content/setStructuresChildren',
        {
          slugPath: `verktoy`,
          query: {
            page: pagination.value ? pagination.value.next : 0,
            limit: 20,
            filter: `{"type": "OBJECT"}`,
          },
        },
      )
      pagination.value = objectsPart.pagination
      loading.value = false
    }

    const loadContent = async () => {
      await store.dispatch('content/setStructure', 'verktoy')
      loadMore()
    }

    onMounted(() => {
      loadContent()
      window.addEventListener('scroll', loadMore)
    })
    onBeforeUnmount(() => {
      window.removeEventListener('scroll', loadMore)
    })

    watch(locale, () => {
      pagination.value = null
      loadContent()
    })

    return {
      store,
      chapter,
      filters,
      files,
      loading,
      canLoadMore,
      loadMore,
    }
  },
}
</script>

<style lang="scss">
.v-tools__wrapper,
.v-tools__items-wrapper {
  margin-top: 3.5rem;
}

.v-tools__description {
  @include bp(large) {
    max-width: 43.75rem;
  }

  p {
    font-size: 1.125rem;
    line-height: 1.3125rem;
  }
}

.v-tools__filters {
  display: none; //EP8-149 - hidden temporary until filters service be ready
  position: sticky;
  top: 0;
}

.v-tools__load-more {
  margin-top: 2rem;
}
</style>
