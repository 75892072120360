<template>
  <div class="c-tools-filters__outer">
    <TheButton class="c-tools-filters__toggle" @click="toggle">
      {{ $t('TOOLS_SHOW' )}}
    </TheButton>
    <div class="c-tools-filters" :class="[show && 'show']">
      <div class="c-tools-filters__wrapper">
        <div class="c-tools-filters__item" v-for="filter in filters" :key="filter.id">
          <TheButton class="c-tools-filters__btn" :class="{'active': filter.id === currentFilter }" @click="switchFilter(filter.id)">
            {{ filter.name }}
          </TheButton>
          <span class="c-tools-filters__item-separator"></span>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue'

import TheButton from '@/components/TheButton'

export default {
  name: 'ToolsFilters',

  props: {
    filters: Array
  },

  components: {
    TheButton
  },

  emits: ['update'],

  setup(props, { emit }) {
    const show = ref(false)
    const currentFilter = ref('')
    const switchFilter = id => {
      currentFilter.value = id
      emit('update', id)
      toggle()
    }
    const toggle = () => show.value = !show.value
    const outsideClick = ev => {
      if (ev.target && ev.target.classList.contains('c-tools-filters__toggle')) {
        return
      }

      show.value = false
    }
    onMounted(() => {
      document.querySelector('body').addEventListener('click', outsideClick)
    })
    onBeforeUnmount(() => {
      document.querySelector('body').removeEventListener('click', outsideClick)
    })

    return {
      switchFilter,
      currentFilter,
      show,
      toggle
    }
  }
}
</script>

<style lang="scss">
$breakpoint-filter: 680px;

.c-tools-filters__outer {
  position: relative;

  @include bp-down($breakpoint-filter) {
    text-align: right;
  }
}

.c-tools-filters__toggle {
  margin-right: 1.5rem;

  @include bp($breakpoint-filter) {
    display: none;
  }

  &::after {
    display: inline-block;
    margin-left: 0.4rem;
    content: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='black' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
}

.c-tools-filters {
  background: $color-white;
  padding: 0.875rem 1.2rem;
  display: none;

  @include bp-down($breakpoint-filter) {
    &.show {
      display: block;
      border-radius: 5px;
      position: absolute;
      right: 1.5rem;
      top: calc(100% + 0.5rem);
      max-width: 12.3125rem;
    }
  }

  @include bp($breakpoint-filter) {
    display: inline-block;
  }
}

.c-tools-filters__wrapper {
  @include bp($breakpoint-filter) {
    display: flex;
    align-items: center;
  }
}

.c-tools-filters__item-separator {
  display: none;

  @include bp($breakpoint-filter) {
    display: inline-block;
    width: 0.125rem;
    height: 1.375rem;
    background: $color-blue-grey;
  }
}

.c-tools-filters__item {
  display: flex;

  @include bp-down($breakpoint-filter) {
    margin: 0.5rem 0;
  }

  &:last-child {
    .c-tools-filters__item-separator {
      display: none;
    }
  }
}

.c-tools-filters__btn {
  font-size: 1rem;
  font-weight: 500;
  color: $color-blue-grey;
  margin: 0 1rem;

  @include bp-down($breakpoint-filter) {
    text-align: left;
  }

  &.active {
    color: $color-blue-dark;
    text-decoration: underline;
  }
}
</style>